import React from 'react'

const Footer = class extends React.Component {
  render() {
    return (
    
        <footer className="footer is-dark">
            <div className="container">
            <div className="columns">
                <div className="column">
                    <div className="level-left">
                    <a className="level-item" href="/privacy-policy/">Legal Disclaimer & Privacy Policy</a>
                    <a className="level-item" href="/about/">About</a>
                    <a className="level-item" href="/contact/">Contact</a></div>
                </div>
                    <div className="column has-text-centered has-text-right-tablet">
                        <p className="subtitle is-6">© Zen Resume Limited. All Rights Reserved.</p>
                    </div>
                    </div>
                </div>
        </footer>
    )
  }
}

export default Footer
